import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(_ctx.$attrs.class),
    type: "submit"
  }, [
    _createElementVNode("span", null, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 2))
}